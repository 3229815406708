const errorCodes = {
  delivery_days_error: "Giorni di consegna mancanti",
  comparison_error: "valore massimo minore del minimo",
  deliveries_per_month_error: "massimo numero di consegne mensile maggiore di 30",
  alternative_rule_error: "regole alternative attive senza regola base",
  international_rule_error: "regola estero non totalmente valorizzata",
  max_order_bigger_than_multiples_error: "multiplo riordino maggiore di massimo ordinabile",
  um_validity_error: "unità di misura non valorizzata",
  multiples_error: "multiplo riordino o unità di misura non valorizzati",
  specific_delivery_days_error: "giorni specifici selezionato ma senza giorni valorizzati",
  multiples_comparison_error: "multiplo di riordino materiale maggiore di quello del fornitore",
  minimum_range_value_error: "range minimo del materiale minore del fornitore",
}

const it_data_entry = {
  materials: "Materiali",
  pallet_order: "Vincoli Riordino",
  parameters: "Parametri",
  item_association: "Associazione Articoli",
  what_article_substitute: "Quali articoli deve sostituire l'articolo",
  product_name: "Nome del prodotto",
  choose_substitute_article: "Seleziona l'articolo da sostituire",
  new: "Nuovo",
  article: "Articolo",
  replace: "Sostituisce",
  replaced_by: "Sostituito da",
  edit: "Modifica",
  unspecified_item: "Articolo sostituito non specificato!",
  penalty_stock: "Minimizzazione volume o valore economico",
  penalty_orders: "Frequenza ordini",
  balanced: "Bilanciato",
  high: "Alto",
  low: "Basso",
  medium: "Medio",
  grouping: "Raggruppamento",
  active: "Attivo",
  supplier_constraints: "Vincoli Fornitore",
  item_constraints: "Vincoli Articolo",
  create_new_grouping: "Crea nuovo raggruppamento",
  grouping_tooltip: "Un raggruppamento è un insieme di più fornitori (determinati da divisione, ragione sociale e indirizzo d'acquisto) per il quale la consegna è effettuata da un solo soggetto",
  unassigned_items: "{unassigned_num} materiali non assegnati",
  unassigned_tooltip: "Per questi materiali non è specificato l'indirizzo d'acquisto tra quelli associati al fornitore: senza questo dato non è possibile procedere al riordino",
  wrong_suppliers: "Fornitori non corretti",
  assign_purchase_addresses: "Assegnazione indirizzi d'acquisto",
  edit_purchase_group: "Modifica Gruppo d'Acquisto {purchase_id}",
  weight_range: "Range Peso",
  weight_range_tooltip: "Specificando un valore minimo e/o massimo, il riordino proposto rispetterà queste limitazioni di peso (Kg)",
  price_range: "Range valore economico",
  price_rage_tooltip: "Specificando un valore minimo e/o massimo, il riordino proposto rispetterà queste limitazioni di valore economico (€)",
  order_range: "Range ordine",
  order_range_tooltip: "Specificando un valore minimo e/o massimo, e la relativa unità di misura, il riordino proposto rispetterà queste limitazioni",
  round_down_range: "Arrotondamento per difetto",
  foreign_range: "Vincoli in presenza di fornitore estero",
  foreign_range_tooltip: "Questa casistica, spesso utile per i fornitori esteri, impone di ordinare il base al vincolo più stringente tra un range di peso e un range di un'altra unità di misura (ad esempio il minimo tra 22 mila Kg e 33 bancali)",
  alternative_round_down_rule: "Regola alternativa difetto",
  alternative_weight_range: "Range Peso alternativo",
  alternative_order_range: "Range ordine alternativo",
  alternative_order_range_tooltip: "Questo vincolo è una seconda opzione rispetto a quanto specificato come range dell'ordine (ad esempio imporre che si possono riordinare massimo 15 bancali oppure 33 bancali)",
  supplier_enabled: "Fornitore Attivo",
  supplier_usage: "Utilizzo Fornitore",
  supplier_usage_tooltip: "Oltre ad attivare o disattivare il fornitore, è possibile stabilire quanto frequentemente si vuole riordinare al fornitore da 1 (raramente) a 3 (spesso)",
  shipment_rules: "Regole di Transporto",
  max_sunday_truck: "Viaggi Massimi Festivi",
  transport_days: "Giorni di trasporto",
  order_multiple: "Multiplo di Riordino",
  multiple: "Multiplo",
  custom_lead_time: "Tempo di consegna personalizzato",
  custom_lead_time_tooltip: "Se specificato, il LEAD TIME CUSTOM verrà preso in considerazione dall'ottimizzatore al posto del Lead Time di default",
  pallet_constraints: "Vincoli Bancali",
  two_ref_per_pallet: "Max 2 referenze per bancale",
  max_orders_per_month: "Max ordini mensili",
  activate_article: "Attiva Articolo",
  apply_all: "Applica a tutti",
  bulk_edit_description: "Stai modificando gli articoli con",
  bulk_edit_choose: "Scegli i campi da modificare",
  safety_days_tooltip: "Questo valore è un input necessario per l'ottimizzatore. La quantità prevista in uscita in questo numero di giorni futuri determina la scorta di sicurezza da imporre al magazzino",
  extra_safety_kg_tooltip: "Al valore di scorsa determinato dai giorni di safety è possibile (non obbligatorio) stabilire una quantità aggiuntiva di sicurezza",
  ...errorCodes
};


export default it_data_entry;