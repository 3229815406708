import {Options} from "@material-table/core";
import React from "react";
import Tooltip from "../../../../ui-components/Tooltip";
import {CheckCircleIcon, MinusIcon} from "@heroicons/react/24/outline";
import {MTColumnsType} from "@utils/pagination";
import {IntlShape} from "react-intl";
import {tableIcons} from "@ui-components/TableIcons";
import {ItemAssociationDetailSchema} from "@feature/entry/types";
import {ERROR_COLOR} from "@theme/index";
import {ICONSTYLE} from "@feature/entry/lib/constants";

type T = ItemAssociationDetailSchema;

const getMinusIcon = () => {
  return <div className="flex items-center">
    <div className={"group relative flex justify-center"}>
      <MinusIcon className="w-5 mx-auto ml-5"/>
    </div>
  </div>
}
export const getColumns = (intl: IntlShape): MTColumnsType<T> => [
  {
    title: intl.formatMessage({id: "new"}),
    field: "is_new_item",
    type: "boolean",
    editable: "never",
    render: (rowData) => {
      if (rowData['replaces'].length === 0 && !rowData['replaced_by'])
        return <CheckCircleIcon className={ICONSTYLE}/>
      else
        return <tableIcons.Check/>
    }
  },
  {
    title: intl.formatMessage({id: "article"}),
    field: "cod_item",
    editable: "never",
    type: "string",
    pagination: {
      spech: {
        column_name: ["cod_item", "des_item"],
        filter_operator: "like",
      }
    },
    render: (rowData: any) => (
      <>
        <b>{rowData['cod_item']}</b> <br/>
        {rowData['des_item']}
      </>
    ),
  },
  {
    title: '',
    field: 'des_item',
    hidden: true,
    type: 'string'
  },
  {
    title: intl.formatMessage({id: "replace"}),
    field: "replaces.cod_product",
    filtering: false,
    sorting: false,
    cellStyle: {textAlign: 'left'},
    render: (rowData: any) => {
      // Se è sostituito, allora non può sostituire alcun articolo
      if (rowData['replaced_by']) {
        return getMinusIcon();
      }
      // Se sostituisce uno o più articoli
      if (rowData['replaces'].length > 0) {
        // Se sostituisce solo se stesso (ovvero non sostiuisce nessun altro articolo)
        if (rowData['replaces'].length === 1 && rowData['replaces'][0]['cod_item'] === rowData['cod_item']) {
          return getMinusIcon();
        }
        // Se sostituisce uno o più articoli, allora mostra anche il numero
        return <div className={"flex flex-row"}>
          <div className={"grow"}>
            {rowData['replaces'].length === 1
              ?
              <>
                <b>{rowData['replaces'][0]['cod_item']}</b><br/>
                {rowData['replaces'][0]['des_product']}
              </>
              :
              <>
                <b>{rowData['replaces'][0]['des_product']}</b><br/>
                {rowData['replaces'].length} articoli sostituiti
              </>
            }
          </div>
        </div>
      } else {
        return <>
          <div className="flex items-center">
            <Tooltip
              customStyle="ml-5"
              styleType="danger"
              text={intl.formatMessage({id: "unspecified_item"})}
              placement='top'/>
          </div>
        </>
      }
    },
  },
  {
    title: intl.formatMessage({id: "replaced_by"}),
    field: "des_product_replaced_by",
    filtering: false,
    sorting: false,
    editable: "never",
    render: (rowData: any) => {
      if (rowData['replaces'].length === 1 && rowData['replaces'][0]['cod_item'] === rowData['cod_item']) {
        return <></>
      } else {
        return <>
          <b>{rowData['replaced_by'] && rowData['replaced_by']['cod_item']}</b> <br/>
          {rowData['replaced_by'] && rowData['replaced_by']['des_item']}
        </>
      }

    },
  },
];

export const OPTIONS: Options<T> = {
  filtering: true,
  toolbar: false,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  showTitle: false,
  filterCellStyle: {maxWidth: 100},
  search: false,
  actionsColumnIndex: -1,
  rowStyle: (rowData) => {
    if (rowData['replaces'].length === 0 && !rowData['replaced_by']) {
      return {backgroundColor: ERROR_COLOR};
    } else {
      return {backgroundColor: ''};
    }
  }

};