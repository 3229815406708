import React, {useContext} from "react";
import {useIntl} from "react-intl";
import {dateToString} from "@utils/index";
import {DataContext} from "@services/DataContext";
import DatePicker from "@ui-components/DatePicker";
import Select from "@ui-components/Select";
import Placeholder from "@ui-components/Placeholder";
import {DemandContextSchema, DetailLevelType, getDimensionRadios} from "@feature/demand";
import RadioGroup from "@ui-components/RadioGroup";
import {resetFilters} from "@feature/commons";

export function FiltersHeader() {

  const intl = useIntl();

  const {
    demandRuns,
    demandCurrentRun,
    setDemandCurrentRun,
    demandFilters,
    setDemandFilters,
    divisions,
    setDemandGroupKey
  } = useContext(DataContext) as DemandContextSchema;

  const {detail_level, ...defaultFilters} = demandFilters;

  const timeIntervals = demandRuns.filter(
    el => el.dat_execution === demandCurrentRun?.dat_execution
  ).map(x => ({value: x.run_label, name: x.run_label}));

  const feasibleDates = demandRuns.reduce<Date[]>((acc, el) => {
    if (!acc.includes(new Date(el.dat_execution)))
      acc.push(new Date(el.dat_execution))
    return acc
  }, [])

  const showTimeIntervals = timeIntervals.length > 1;


  return (
    <div
      className={`flex flex-col my-5 mx-10 md:flex-row ${showTimeIntervals ? 'justify-evenly' : 'justify-between'} items-center`}>
      {
        feasibleDates.length && demandCurrentRun?.dat_execution
          ? <DatePicker
            value={new Date(demandCurrentRun?.dat_execution)}
            selectableDates={feasibleDates as any}
            minDate={new Date((new Date().getFullYear() - 1).toString())}
            onChange={(date: Date) => {
              setDemandCurrentRun(
                demandRuns.find(el => el.dat_execution === dateToString(date))!
              );
              setDemandFilters({
                ...resetFilters(defaultFilters),
                detail_level: 'daily',
                division: divisions?.map(el => el.cod_division),
              });
              setDemandGroupKey(getDimensionRadios(intl)[0])
            }}
            label={intl.formatMessage({id: "data_label"})}
            placeholder="Inserisci data"
            errorMessage="Seleziona data"
          />
          : <Placeholder height="h-20"/>
      }
      {
        timeIntervals.length
          ? showTimeIntervals && (
          <Select
            options={timeIntervals}
            customStyles={{minWidth: 120}}
            value={demandCurrentRun?.run_label}
            onChange={(e: {
              target: {
                value: string;
              };
            }) => {
              setDemandCurrentRun(
                demandRuns.find(el => el.run_label === e.target.value)!
              );
              setDemandFilters({
                ...resetFilters(defaultFilters),
                detail_level: 'daily',
                division: divisions?.map(el => el.cod_division),
              });
              setDemandGroupKey(getDimensionRadios(intl)[0])
            }}
            label={intl.formatMessage({id: "set_hour"})}
          />
        )
          : <Placeholder height="h-20"/>
      }
      <RadioGroup
        title={intl.formatMessage({id: "period"})}
        options={[
          {value: "daily", name: intl.formatMessage({id: "daily"})},
          {value: "weekly", name: intl.formatMessage({id: "weekly"})},
        ]}
        currentValue={demandFilters.detail_level}
        onChange={(value: DetailLevelType) => setDemandFilters({
          ...demandFilters,
          detail_level: value
        })}
        horizontal={false}/>
    </div>
  )
}