import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from "react-intl";
import _ from "lodash";
import {api} from "@services/apiRequest";

import Modal from "@ui-components/Modal";
import Button from "@ui-components/Button";
import SearchSelect from "@ui-components/SearchSelect";
import {NotificationsContext} from "@ui-components/Notifications";
import {AssignModalProps, PalletizationItemWithAddressSchema} from "@feature/entry";
import {ArticleProps, Carousel, IS_DC_ENABLED, OptionSchema} from "@feature/commons";
import {saveAssignedItems} from "@feature/entry/palletization/lib/utils";

export function AssignModal({opened, onExit, unassignedItems, retriggerUnassignedCount}: AssignModalProps) {
	const {push} = useContext(NotificationsContext);
	const intl = useIntl();
	
	const windowHeight = useRef<number>(window.innerHeight);
	
	const elementPerPage = windowHeight.current > 800 ? 5 : 3;
	
	const msg = useCallback((msgId: string) => intl.formatMessage({id: msgId}), [intl]);
	
	const resetStates = () => {
		setOffset(0);
		setNewAssignedItems([]);
		onExit();
	}
	
	const [newAssignedItems, setNewAssignedItems] = useState<PalletizationItemWithAddressSchema>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [offset, setOffset] = useState<number>(0);
	
	useEffect(() => {
		if (!opened)
			return;
		
		setNewAssignedItems(unassignedItems);
	}, [unassignedItems, opened]);
	
	
	// by default, slice creates only a shallow copy of the array, so we need to clone it to trigger a rerender
	const slicedItemArray = _.clone(newAssignedItems.slice(offset * elementPerPage, offset * elementPerPage + elementPerPage))
	
	
	const fetchItemsFn = useCallback(
		(query: string, cod_dc: string, cod_supplier: string, cod_division: string) => new Promise<OptionSchema[]>((resolve) => {
			setLoading(true)
			// adding timeout to avoid firing too many requests
			const params = new URLSearchParams({
				search_keyword: query,
				cod_dc,
				cod_supplier,
				cod_division
			});
			setTimeout(
				() => api.get<OptionSchema[]>(`/entry/palletization/assignable_addresses?${params.toString()}`)
					.then(r => {
						resolve(r.data);
					})
					.catch(() => {
						push({title: msg('generic_error'), type: "error"});
						resolve([]);
					})
					.finally(() => setLoading(false)), 1000
			)
		}), [msg, push])
	
	if (!opened)
		return <></>;
	
	return (
		<Modal onExit={resetStates} opened={opened} classNames="flex flex-col gap-y-6">
			<h1 className="text-xl font-semibold text-am-600 text-center">{msg("assign_purchase_addresses")}</h1>
			{
				slicedItemArray.map((uI, index) => (
					<div key={index}>
						<div className="flex flex-col gap-y-2">
							{IS_DC_ENABLED && <span>{msg('warehouse')}: <b>{uI['cod_dc']}</b> - {uI['des_dc']}</span>}
							<span>{msg("Fornitore")}: <b>{uI['cod_supplier']}</b> - {uI['business_name']}</span>
							<span>{msg("Materiale")}: <b>{uI['cod_item']}</b> - {uI['des_item']}</span>
						</div>
						<SearchSelect
							value={{label: uI.des_purchase_address, value: uI.cod_purchase_address}}
							loadOptions={(inputValue: string) => fetchItemsFn(inputValue, uI.cod_dc, uI.cod_supplier, uI.cod_division)}
							loadingMessage={() => intl.formatMessage({id: "loading"})}
							noOptionsMessage={() => intl.formatMessage({id: "type_something"})}
							onChange={(e: OptionSchema | null) => setNewAssignedItems(prevState => {
								const newState = _.cloneDeep(prevState);
								newState[index].cod_purchase_address = e?.value;
								newState[index].des_purchase_address = e?.label;
								return newState;
							})}
							isMulti={false}
							isLoading={loading}
							cacheOptions
							defaultOptions
							isClearable={newAssignedItems[index].cod_purchase_address} // show clear button only if there is a value
							CustomOption={({data, innerProps, innerRef}: ArticleProps<OptionSchema>) => (
								<article ref={innerRef} {...innerProps}
								         className="px-4 py-2 text-sm cursor-pointer hover:bg-blue-100">
									<span><b>{data.value}</b> - {data.label}</span>
								</article>
							)}
							options={undefined} label={undefined}/>
					</div>
				))
			}
			{
				newAssignedItems.length > elementPerPage &&
        <Carousel
          totalItems={newAssignedItems.length}
          itemsPerPage={elementPerPage}
          onPageChange={pageNumber => setOffset(pageNumber)}/>
			}
			<div className="flex justify-between">
				<Button onClick={resetStates}>{msg("exit")}</Button>
				<Button
					onClick={() => saveAssignedItems( // save only the edited subset
						newAssignedItems.filter(el => el.cod_purchase_address),
						push,
						intl
					).then(() => {
						retriggerUnassignedCount();
						onExit();
					})
					}
					disabled={!newAssignedItems.some(i => i.cod_purchase_address)}
					submitting={loading}>
					{msg("save")}
				</Button>
			</div>
		</Modal>
	)
}