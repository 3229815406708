const it_aware_generic = {
  week: "Settimana",
  weeks: "Settimane",
  day: "Giorno",
  days: "Giorni",
  month: "Mese",
  months: "Mesi",
  last_weeks: "Ultime {weeknum} settimane",
  delivery_and_multiples_warning: "Né la data di consegna né la quantità desiderata sono cambiati",
  order_date_before_today: "La data di ordine non può essere nel passato",
  add_supplier_group: "Crea un nuovo gruppo di fornitori",
  add_supplier_group_description: "Per creare un nuovo gruppo fornitori, è necessario inserire almeno 2 entità di riordino e specificare l'opzione di consegna",
  supplier_group_list: "Entità di riordino",
  delivery: "Consegna",
  cancel: "Annulla",
  delete: "Elimina",
  preference: "Preferenza",
  delivery_days: "Giorni di consegna",
  delivery_days_tooltip: "È possibile stabilire dei giorni specifici di consegna dei singoli materiali. Se non specificati, ogni materiale segue i giorni di consegna del fornitore",
  delivery_days_error: "Seleziona almeno un giorno di consegna",
  value_range_error: "il valore minimo deve essere minore uguale di quello massimo",
  um_error: "unità di misura mancante",
  filter: "Filtra ",
  all: "Tutti",
  emergency: "Urgenti",
  already_suggested: "Esiste già un ordine previsto per la data di consegna selezionata. Modifica la quantità o la data di consegna della previsione esistente.",
};

export default it_aware_generic;