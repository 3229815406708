import React from "react";
import {
  AdjustmentsVerticalIcon,
  ArrowTrendingUpIcon,
  HomeIcon,
  ShoppingBagIcon,
  ShoppingCartIcon
} from "@heroicons/react/24/outline";

const Home = React.lazy(() =>
  import("@pages/Home")
);
const DataEntry = React.lazy(() =>
  import("@pages/DataEntry")
);
const Inventory = React.lazy(() =>
  import("@pages/Inventory")
);
const Orders = React.lazy(() =>
  import("@pages/Orders")
);
const Demand = React.lazy(() =>
  import("@pages/DemandForecast")
);

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
    hidden: false,
  },
  {
    name: "demand_forecasting",
    href: "/demand",
    Icon: ArrowTrendingUpIcon,
    component: Demand,
    hidden: false,
  },
  {
    name: "inventory",
    href: "/inventory",
    Icon: ShoppingCartIcon,
    component: Inventory,
    hidden: false,
  },
  {
    name: "orders",
    href: "/orders",
    Icon: ShoppingBagIcon,
    component: Orders,
    hidden: false,

  },
  {
    name: "data_entry",
    href: "/data",
    Icon: AdjustmentsVerticalIcon,
    component: DataEntry,
    hidden: false,
  },
];

export default routes;
