const orderStatusTranslations = {
  COMPLETED: "Completed",
  CONFIRMED: "Confirmed",
  SENT: "Sent",
  LATE: "Late",
  ERROR: "Error",
}

const en_orders = {
  delivery_date: "Delivery date",
  historic_orders: "Historic orders",
  no_orders_message: "Select the products to order on the Warehouse page",
  delete_order_text: "Do you really want to delete the order?",
  send_order_text: "Do you really want to send the order?",
  send_orders: "Send orders",
  orders_summary: "Orders summary",
  status: "Status",
  cod_order: "Order code",
  dat_order: "Order date",
  confirmation_time: "Confirmation time",
  delivery_time: "Delivery time",
  ...orderStatusTranslations
};

export default en_orders;