import {BasicInputProps} from "@feature/entry";
import Input from "@ui-components/Input";
import React, {ChangeEvent} from "react";


export const BasicInput = ({
	                           value,
	                           label,
	                           onChange,
	                           classNames = "",
	                           isValid,
	                           errorMessage = ""
                           }: BasicInputProps) => (
	<div className="block">
		<Input
			value={value?.toString()}
			classNames={classNames}
			label={label}
			labelClassNames="text-sm font-semibold"
			onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(
				e.target.value !== '' && !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : undefined
			)}
			name={undefined}
			showCalendar={undefined}
			onClear={undefined}
			validation={undefined}
			errorMessage={undefined}
			required={undefined}/>
		{!isValid && <span className="text-red-500 text-xs font-semibold">{errorMessage}</span>}
	</div>
);
	