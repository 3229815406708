import React, {ChangeEvent, useCallback, useContext} from "react";
import {useIntl} from "react-intl";

// Services
import {api} from "@services/apiRequest";
import Modal from "@ui-components/Modal";
import DatePicker from "@ui-components/DatePicker";
import Button from "@ui-components/Button";
import Input from "@ui-components/Input";
import {NotificationsContext} from "@ui-components/Notifications";
import {changeMonth, dateToString} from "@utils/index";
import {DataContext} from "@services/DataContext";
import {InventoryContextSchema} from "@feature/inventory/types";
import {ModifyRowDialogPropsType} from "../types";
import CheckModifyOrder from "./check-modify-order";


export function ModifyRowDialog({open, toggleOpen, data, setData, refresh}: ModifyRowDialogPropsType) {
	
	const intl = useIntl();
	
	const {push} = useContext(NotificationsContext);
	
	const {inventoryCurrentRun} = useContext(DataContext) as InventoryContextSchema;
	
	const msg = (id: string) => intl.formatMessage({id});
	
	const handleSave = useCallback(
		async (e) => {
			e.preventDefault();
			
			if (
				data.new_dat_delivery === data.dat_delivery ||
				data.new_val_quantity_multiples === data.val_quantity_multiples
			) {
				push({type: "warning", title: intl.formatMessage({id: 'delivery_and_multiples_warning'})});
				return;
			}
			
			const payload = {...data, id_run: inventoryCurrentRun?.id_run};
			
			await api.put("/inventory/update_inventory_row", payload).then(() => {
				toggleOpen();
				refresh();
				push({type: "success", title: intl.formatMessage({id: 'success'})});
			})
				.catch((err) => {
					console.error(err)
					push({type: "error", title: intl.formatMessage({id: 'generic_error'})})
				})
		},
		[inventoryCurrentRun?.id_run, data, toggleOpen, refresh, push, intl])
	
	if (!open) return null;
	
	return (
		<Modal opened={open} maxWidth="sm:max-w-2xl"
		       classNames="flex flex-col gap-y-10 items-center text-center" onExit={toggleOpen}>
			<h1 className="font-bold text-2xl">Modifica la data di riordino e/o la quantità </h1>
			<div className="flex flex-col items-start gap-y-5">
				<p><b>{msg("warehouse")}:</b> {data.cod_dc} - {data.des_dc}</p>
				<p><b>{msg("Materiale")}:</b> {data.cod_item} - {data.des_item}</p>
				<p><b>{msg("Fornitore")}:</b> {data.cod_supplier} - {data.business_name}</p>
				<p><b>{msg("Divisione")}:</b> {data.des_division}</p>
			</div>
			<form onSubmit={handleSave} className="flex flex-col gap-y-4 items-center">
				<b>{msg("add_row_dat_order")}:</b>
				<div className="w-40">
					<DatePicker
						value={new Date(data.new_dat_order ?? data.dat_order!)}
						onChange={(d: Date) => setData(prevState => ({...prevState, new_dat_order: dateToString(d)}))}
						minDate={changeMonth(new Date(), -1)}/>
				</div>
				{
					data.new_dat_order && new Date(data.new_dat_order) < new Date() && (
						<span className="text-red-500">{msg("order_date_before_today")}</span>
					)
				}
				<b>{msg("add_row_val_quantity_multiples")} : </b>
				<div className="flex items-center justify-center gap-x-4">
					<Input
						classNames="w-14"
						value={data.new_val_quantity_multiples ?? data.val_quantity_multiples}
						name="val_quantity_multiples"
						onChange={(e: ChangeEvent<HTMLInputElement>) => setData({
							...data,
							new_val_quantity_multiples: parseFloat(e.target.value)
						})}
						validation={() => true}
						label={undefined}
						required
						errorMessage={undefined}
						showCalendar={undefined}
						onClear={undefined}/>
					<b>{data.um_multiples}</b>
				</div>
				<CheckModifyOrder
					data={data}
					setData={setData}/>
				<Button className="mt-5" type="submit" disabled={
					!data.new_dat_delivery && !data.new_val_quantity_multiples
					|| (!!data.new_dat_order && new Date(data.new_dat_order) < new Date())
				}>
					{intl.formatMessage({id: "save"})}
				</Button>
			</form>
		</Modal>
	);
}