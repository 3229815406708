import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {Query} from "@material-table/core";
import {MTColumnsType, OrderingSchema} from "@utils/pagination";
import {DataContext} from "@services/DataContext";
import {InventoryContextSchema} from "@feature/inventory/types";
import {ExcelDownload, fetchPagination} from "../../../commons/utils";
import {castFiltersToArrays} from "../../../commons";

export function useInventoryReplenishment<T extends object>(
  runId: number,
  columns: MTColumnsType<T>,
  urgent: boolean
) {
  // hooks
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();

  const {inventoryFilters} = useContext(DataContext) as InventoryContextSchema;

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const baseUrl = `/inventory/${runId}/replenishment`;

  const inventoryPayload = {
    inventory_filters: castFiltersToArrays(inventoryFilters),
    urgent
  }

  if (!inventoryFilters.division)
    return {}
	
	const fetch = async (query: Query<T>, additionalOrderingFields: OrderingSchema['fields']) => fetchPagination(
    baseUrl,
    query,
    columns,
    undefined,
    inventoryPayload,
    push,
    setLoading,
		throwError,
		additionalOrderingFields
  )

  const download = async (name: string, query?: Query<T>, columnMappings?: { [key: string]: string }) => ExcelDownload(
    baseUrl,
    columns,
    name,
    query,
    columnMappings,
    setLoading,
    push,
    throwError,
    inventoryPayload
  );

  return {fetch, download, loading};
}