// ##### DATE FUNCTIONALITIES ######
import AuthenticationService from "@services/AuthenticationService";

export const getDateOfISOWeek = (weekNumber, year) => {
  // given week number and year, return a Date object
  // with day as the first day of the week
  let simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  let dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4)
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

// function that convert date to YYYY-MM-DD format

/**
 * Convert date to string
 * @param {Date} d
 * @returns {string}
 */

export const dateToString = (d) => new Intl.DateTimeFormat('fr-CA', {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
}).format(d);

export const GetUserScopes = () => {
  let userScopes = null;
  if (AuthenticationService.isUserAuthenticated()) {
    userScopes = AuthenticationService.getUserScopes();
  }
  return userScopes
};

export const changeYear = (_date, _delta) => {
  _date.setFullYear(_date.getFullYear() + _delta);
  return _date;
}

export const changeMonth = (_date, _delta) => {
  _date.setMonth(_date.getMonth() + _delta);
  return _date;
}

export const changeWeek = (_date, _delta) => {
  _date.setDate(_date.getDate() + 7 * _delta);
  return _date;
}

/**
 * Date difference
 * @param {Date | string | undefined} date1
 * @param {Date | string | undefined} date2
 * @return {number}
 */

export const dateDiff = (date1, date2) => {
  let d1 = date1;
  let d2 = date2;

  if (!d1 || !d2)
    return

  if (typeof d1 === 'string')
    d1 = new Date(d1)

  if (typeof d2 === 'string')
    d2 = new Date(d2)

  return Math.ceil(Math.abs(d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24))
}

