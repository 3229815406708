import React, {useContext} from "react";
import {useIntl} from "react-intl";
import {dateToString} from "@utils/index";
import {DataContext} from "@services/DataContext";
import Tooltip from "@ui-components/Tooltip";
import DatePicker from "@ui-components/DatePicker";
import Select from "@ui-components/Select";
import Placeholder from "@ui-components/Placeholder";
import {AlertsDashboard} from "../../alert";
import {InventoryContextSchema} from "@feature/inventory/types";
import {resetFilters} from "@feature/commons";

export function FiltersHeader() {

  const intl = useIntl();

  const {
    inventoryRuns,
    inventoryCurrentRun,
    setInventoryCurrentRun,
    setInventoryFilters,
    divisions
  } = useContext(DataContext) as InventoryContextSchema;

  const timeIntervals = inventoryRuns.filter(
    el => el.dat_execution === inventoryCurrentRun?.dat_execution
  ).map(x => ({value: x.run_label, name: x.run_label}));

  const feasibleDates = inventoryRuns.reduce<Date[]>((acc, el) => {
    if (!acc.includes(new Date(el.dat_execution)))
      acc.push(new Date(el.dat_execution))
    return acc
  }, [])


  return (
    <div className="flex flex-col gap-y-2 md:gap-y-0 md:flex-row justify-evenly items-center">
      <div className="flex items-center justify-evenly gap-x-2">
        <Tooltip
          placement="right"
          text={intl.formatMessage({id: "inventoryRunsTooltip"})}
        />
        {
          feasibleDates.length && inventoryCurrentRun?.dat_execution
            ? <DatePicker
              value={new Date(inventoryCurrentRun?.dat_execution)}
              selectableDates={feasibleDates as any}
              minDate={new Date((new Date().getFullYear() - 1).toString())}
              onChange={(date: Date) => {
                setInventoryCurrentRun(
                  inventoryRuns.find(el => el.dat_execution === dateToString(date))!
                );
                setInventoryFilters(prevState => ({
                  ...resetFilters(prevState),
                  division: divisions?.map(el => el.cod_division),
                }));
              }}
              label={intl.formatMessage({id: "data_label"})}
              placeholder="Inserisci data"
              errorMessage="Seleziona data"
            />
            : <Placeholder height="h-20"/>
        }
        {
          timeIntervals.length
            ? <Select
              options={timeIntervals}
              customStyles={{minWidth: 120}}
              value={inventoryCurrentRun?.run_label}
              onChange={(e: {
                target: {
                  value: string;
                };
              }) => {
                setInventoryCurrentRun(
                  inventoryRuns.find(el => el.run_label === e.target.value)!
                );
                setInventoryFilters(prevState => ({
                  ...resetFilters(prevState),
                  division: divisions?.map(el => el.cod_division),
                }));
              }}
              label={intl.formatMessage({id: "set_hour"})}
            />
            : <Placeholder height="h-20"/>
        }
      </div>

      {
        !divisions || !inventoryCurrentRun?.id_run
          ? <Placeholder height="h-20"/>
          : <AlertsDashboard
            runId={inventoryCurrentRun.id_run!}
            divisions={divisions.map(({des_division}) => des_division)}
          />
      }
    </div>
  )
}