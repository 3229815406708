const en_aware_generic = {
  week: "Week",
  weeks: "Weeks",
  day: "Day",
  days: "Days",
  month: "Month",
  months: "Months",
  last_weeks: "Last {weeknum} weeks",
  delivery_and_multiples_warning: "Neither the delivery date nor the desired quantity are changed",
  order_date_before_today: "Order date cannot be in the past",
  add_supplier_group: "Create a new supplier group",
  add_supplier_group_description: "To create a new supplier group, you must enter at least 2 reorder entities and specify the delivery option",
  supplier_group_list: "Reorder entities",
  delivery: "Delivery",
  cancel: "Cancel",
  delete: "Delete",
  preference: "Preference",
  delivery_days: "Delivery days",
  delivery_days_tooltip: "You can set specific delivery days for each material. If not specified, each material follows the supplier's delivery days",
  delivery_days_error: "Select at least one delivery day",
  value_range_error: "the minimum value must be less than or equal to the maximum value",
  um_error: "missing unit of measure",
  filter: "Filter ",
  all: "All",
  emergency: "Urgent",
  already_suggested: "There is already a forecast order for the selected delivery date. Modify the quantity or delivery date of the existing suggestion.",
};

export default en_aware_generic;