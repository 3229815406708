import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {DataContext} from "@services/DataContext";
import {InventoryContextSchema, InventoryRunsTypes} from "@feature/inventory/types";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";

export function useInventoryRuns() {

  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const intl = useIntl();

  const {
    inventoryRuns,
    setInventoryRuns,
    setInventoryCurrentRun,
  } = useContext(DataContext) as InventoryContextSchema;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (!inventoryRuns.length)
      api.get<InventoryRunsTypes[]>("inventory/available_runs")
        .then(({data}) => {
          setInventoryRuns(data);
          setInventoryCurrentRun(data[0]);
        })
        .catch((error) => {
          push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
          throwError(error);
        });
    setLoading(false);
  }, [intl, inventoryRuns.length, push, setInventoryCurrentRun, setInventoryRuns, throwError]);

  return loading;

}