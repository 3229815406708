import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import Modal from "@ui-components/Modal";
import {BulkEditModalProps, DtnItemResponseSchema, EditFieldsType, MTColumnEditType} from "@feature/entry";
import Button from "@ui-components/Button";
import {NotificationsContext} from "@ui-components/Notifications";
import {fieldAlreadyExists, getChangeList, getEditComponent, handleFieldChange, handleSave} from "./lib/utils";

type T = DtnItemResponseSchema;


export function BulkEditModal({
                                bulkEditValues,
                                open,
                                onExit,
                                isAllBulkEdit,
                                refresh,
                                tableColumns,
                                query
                              }: BulkEditModalProps<T>) {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);

  const onExitRefresh = () => {
    onExit();
    setEditFields([]);
  }

  const msg = (id: string) => intl.formatMessage({id})

  const getTitle = (columnTitle: MTColumnEditType<T>['title'], columnEditTitle: MTColumnEditType<T>['editTitle']) => {
    if (!['string', 'undefined'].includes(typeof columnEditTitle) && columnTitle === undefined)
      throw new Error('set editTitle in columns Props if title is a React Component')
    else
      return columnEditTitle ?? columnTitle
  }

  const [editFields, setEditFields] = useState<EditFieldsType<T>[]>([]);

  return (
    <Modal opened={open} onExit={onExitRefresh} maxWidth="sm:max-w-xl">
      <div className="flex flex-col items-center gap-y-7">
        <h1 className="text-2xl font-bold capitalize">{msg('edit')}</h1>
        <h2 className="text-xl font-bold capitalize">{msg('bulk_edit_description')}</h2>
        {getChangeList(bulkEditValues, isAllBulkEdit, intl)}
        <hr className="border w-full rounded-md"/>
        <p>{msg('bulk_edit_choose')}</p>
        <div className="flex justify-evenly items-center w-full">
          {
            tableColumns.filter(c => c.editable !== "never" && !c.hidden)
              .map(
                ({title, field, type, lookup, editTitle}) => (
                  <div
                    key={field as string}
                    onClick={() => handleFieldChange({
                      field,
                      type,
                      title,
                      lookup,
                      editTitle
                    }, setEditFields, editFields)}
                    className={`${
                      fieldAlreadyExists(field as string, editFields)
                        ? 'bg-indigo-600 text-white scale-110'
                        : 'bg-white text-indigo-600'
                    } transform transition capitalize rounded-md text-sm p-1 border-2 border-indigo-600 hover:bg-indigo-500 hover:text-white hover:cursor-pointer`}>
                    {getTitle(title, editTitle)}
                  </div>
                )
              )
          }
        </div>
        <div className="flex flex-col gap-y-3 w-full h-full px-10 mt-5 capitalize">
          {
            editFields.map(
              ({title, field, type, lookup, value, editTitle}) => (
                <div key={field as string} className="flex items-center justify-between animate-fadeIn">
                  <b>{getTitle(title, editTitle)}</b>
                  {getEditComponent(type ?? 'string', lookup, value, field, setEditFields)}
                </div>
              )
            )
          }
        </div>
        <Button
          onClick={() => handleSave(
            editFields,
            bulkEditValues,
            query,
            tableColumns,
            isAllBulkEdit,
            onExitRefresh,
            refresh,
            push,
            intl
          )}>{msg("save")}</Button>
      </div>
    </Modal>
  )
}