import React, {FormEvent, useContext, useState} from "react";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import Modal from "@ui-components/Modal";
import DatePicker from "@ui-components/DatePicker";
import SearchSelect from "@ui-components/SearchSelect";
import Button from "@ui-components/Button";
import {DEFAULT_DC_VALUES, IS_DC_ENABLED, OptionSchema} from "@feature/commons";
import {dateToString} from "@utils/index";
import CheckCustomOrder from "./check-customer-order";
import {DataContext} from "@services/DataContext";
import {loadOptionsAsync} from "./lib/utils";
import {CustomOptionPropsType, InventoryReplenishmentExtendedSchema, ModalPropsType, TypeEnum} from "../types";
import {InventoryContextSchema} from "../../types";


export function AddRowDialog({open, toggleOpen, refresh}: ModalPropsType) {
	
	const todayString = dateToString(new Date());
	
	const {push} = useContext(NotificationsContext);
	
	const intl = useIntl();
	
	const {inventoryCurrentRun} = useContext(DataContext) as InventoryContextSchema;
	
	const [newItem, setNewItem] = useState<Partial<InventoryReplenishmentExtendedSchema>>({dat_order: todayString, ...DEFAULT_DC_VALUES});
	
	const resetModal = () => {
		setNewItem({dat_order: todayString, ...DEFAULT_DC_VALUES});
		toggleOpen();
	}
	
	const _onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		
		const payload = {
			...newItem,
			dat_delivery: newItem.new_dat_delivery,
			id_run: inventoryCurrentRun?.id_run,
			dat_execution: inventoryCurrentRun?.dat_execution
		}
		
		api.post("/inventory/add_inventory_row", payload).then(() => {
			push({type: "success", title: intl.formatMessage({id: 'success'})});
			resetModal();
			refresh();
		})
			.catch(() => push({type: "error", title: intl.formatMessage({id: 'generic_error'})}));
	}
	
	if (!open) return null;
	
	
	return (
		<Modal opened={open} onExit={resetModal} classNames="flex flex-col items-center gap-y-4 my-4">
			<h3 className="text-center text-2xl font-extrabold text-gray-900">
				{intl.formatMessage({id: "add_row_choose"})}
			</h3>
			<form onSubmit={_onSubmit} className="flex flex-col items-center gap-y-6">
				<DatePicker
					value={new Date(newItem.dat_order!)}
					classNames="w-42"
					labelClassNames="font-bold text-sm"
					onChange={(d: Date) => setNewItem(prevState => ({...prevState, dat_order: dateToString(d)}))}
					label={intl.formatMessage({id: "add_row_dat_order"})}
					placeholder={intl.formatMessage({id: "add_row_dat_order"})}
					errorMessage=""
					minDate={undefined}/>
				{
					IS_DC_ENABLED && (
						<div className="w-60">
							<SearchSelect
								label={intl.formatMessage({id: "add_row_warehouse"})}
								labelClassNames="font-bold text-sm"
								options={undefined}
								cacheOptions
								defaultOptions
								value={{value: newItem.cod_dc, label: newItem.des_dc}}
								onChange={(e: OptionSchema | undefined) => {
									const newValue = e ? e.value : undefined;
									const newLabel = e ? e.label : undefined;
									setNewItem(({dat_order}) => ({dat_order, cod_dc: newValue, des_dc: newLabel}))
								}}
								loadOptions={(inputValue: string) => loadOptionsAsync(inputValue, TypeEnum.DC)}
								CustomOption={({data, innerProps}: CustomOptionPropsType) =>
									<article {...innerProps} className="px-4 py-2 text-sm cursor-pointer hover:bg-am-400">
										<b>{data.value}</b> - {data.label}
									</article>}
								isClearable={newItem.cod_dc}
								isMulti={false}/>
						</div>
					)
				}
				{
					newItem.cod_dc && (
						<div className="w-60">
							<SearchSelect
								label={intl.formatMessage({id: "add_row_item"})}
								labelClassNames="font-bold text-sm"
								value={{value: newItem.cod_item, label: newItem.des_item}}
								onChange={(e: OptionSchema | undefined) => {
									const newValue = e ? e.value : undefined;
									const newLabel = e ? e.label : undefined;
									setNewItem(({dat_order, cod_dc, des_dc}) => ({
										dat_order,
										cod_dc,
										des_dc,
										cod_item: newValue,
										des_item: newLabel
									}))
								}}
								options={undefined}
								cacheOptions
								defaultOptions
								loadOptions={(inputValue: string) => loadOptionsAsync(inputValue, TypeEnum.ITEM, newItem.cod_dc)}
								CustomOption={({data, innerProps}: CustomOptionPropsType) =>
									<article {...innerProps} className="px-4 py-2 text-sm cursor-pointer hover:bg-am-400">
										<b>{data.value}</b> - {data.label}
									</article>}
								isClearable={newItem.cod_item}
								isMulti={false}/>
						</div>
					)
				}
				<CheckCustomOrder data={newItem}
				                  setData={setNewItem}
				                  disabled={!Object.keys(newItem).length && !newItem.dat_order}/>
				<Button disabled={!newItem.val_quantity_multiples} type="submit">
					{intl.formatMessage({id: "add"})}
				</Button>
			</form>
		</Modal>
	);
}
