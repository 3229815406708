import {Dispatch, SetStateAction} from "react";
import apiRequest from "@services/apiRequest";
import {GroupedOrderType} from "@feature/orders";

export const handleSendOrders = (
	grouped_orders: GroupedOrderType[],
	setRefetchOrders: Dispatch<SetStateAction<boolean>>,
	setLoading: Dispatch<SetStateAction<boolean>>
) => {
	setLoading(true);
	const flattenOrders = grouped_orders.flatMap(gO => gO.items.map(it => ({
		cod_dc: it.cod_dc,
		cod_supplier: gO.cod_supplier,
		cod_purchase_address: gO.cod_purchase_address,
		cod_item: it.cod_item,
		dat_delivery: gO.dat_delivery
	})))
	apiRequest.post('orders/send', flattenOrders).then(() => setRefetchOrders(p => !p))
		.catch(e => console.error(e))
		.finally(() => setLoading(false));
}