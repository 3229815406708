import {IntlShape} from "react-intl";
import {DimensionMapType} from "../../types";

export const getDimensionFilterMapping = (intl: IntlShape): DimensionMapType => {
  const msg = (_key: string) => intl.formatMessage({id: _key});

  return {
    warehouse: {
      label: msg("warehouse"),
      tooltip_text: msg("tooltip_demand_filter"),
    },
    area: {
      label: msg("Famiglia"),
    },
    supplier: {
      label: msg("Fornitore"),
    },
    product: {
      label: msg("Gruppo_merce"),
    }
  }
}