import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {PalletizationItemSchema} from "@feature/entry";

export function useUnassignedItems() {
	
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	const intl = useIntl();
	
	const [unassignedItems, setUnassignedItems] = useState<PalletizationItemSchema[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [trigger, setTrigger] = useState<boolean>(false);
	
	useEffect(() => {
		setLoading(true);
		api.get<PalletizationItemSchema[]>("entry/palletization/item/unassigned")
			.then(({data}) => {
				setUnassignedItems(data);
			})
			.catch((error) => {
				push({title: intl.formatMessage({id: 'generic_error'}), type: "error"});
				throwError(error);
			});
		setLoading(false);
	}, [intl, push, throwError, trigger]);
	
	return {loading, unassignedItems, retriggerUnassignedCount: () => setTrigger(p => !p)};
	
}