import React, {useMemo} from "react";
import MaterialTable from "@material-table/core";
import {PageSection} from "@ui-components/Container";
import TableStyle from "@ui-components/TableStyle";
import {OrderType} from "@feature/orders";
import {getColumns} from "./params";
import {useIntl} from "react-intl";
import {getCommonProps} from "@feature/commons";
import {downloadExcel} from "@utils/downloadExcel";
import {dateDiff} from "@utils/index";

type T = OrderType;

export function HistoricOrders({tableData}: { tableData: T[] }) {
	
	const intl = useIntl();
	
	const ref = React.useRef<any>();
	
	const msg = (id: string) => intl.formatMessage({id});
	
	const commonProps = getCommonProps<T>(intl, {emptyRowsWhenPaging: false});
	
	const columns = useMemo(() => getColumns(intl), [intl]);
	
	
	return (
		<PageSection title={intl.formatMessage({id: 'historic_orders'})}>
			<TableStyle>
				<MaterialTable
					data={tableData}
					tableRef={ref}
					title=""
					columns={columns}
					{...commonProps}
					actions={[
						{
							...commonProps.downloadAction,
							onClick: () => downloadExcel<T>({
								exportData: (ref.current?.state.data as T[]).map(el => ({
									...el,
									confirmation_time: dateDiff(el.tms_confirmation, el.tms_sent) && `${dateDiff(el.tms_confirmation, el.tms_sent)} ${msg('days')}`,
									delivery_time: dateDiff(el.tms_completion, el.tms_sent) && `${dateDiff(el.tms_completion, el.tms_sent)} ${msg('days')}`
								})),
								columns,
								name: msg("historic_orders")
							}),
						},
					]}
				/>
			</TableStyle>
		</PageSection>
	)
}