import {Dispatch, SetStateAction} from "react";
import {ArticleProps, FilterGroupSchema, FiltersSchema, OptionSchema, Ordering} from "@feature/commons";
import {OrderStatus} from "@feature/orders";

interface InventoryReplenishmentSchema {
	/**
	 * Cod Dc
	 * @default "000"
	 */
	cod_dc: string;
	/** Cod Supplier */
	cod_supplier: string;
	/** Business Name */
	business_name: string;
	/** Cod Purchase Address */
	cod_purchase_address: string;
	/** Des Purchase Address */
	des_purchase_address: string;
	/** Cod Item */
	cod_item: string;
	/** Des Item */
	des_item: string;
	/** Cod Product */
	cod_product: string;
	/** Des Product */
	des_product: string;
	/** Cod Division */
	cod_division: string;
	/** Des Division */
	des_division: string;
	/** Cod Area */
	cod_area: string;
	/** Des Area */
	des_area: string;
	/** Flg Order On Sale */
	flg_order_on_sale: boolean;
	/**
	 * Dat Order
	 * @format date
	 */
	dat_order: string;
	/**
	 * Dat Delivery
	 * @format date
	 */
	dat_delivery: string;
	/** Um Multiples */
	um_multiples: string;
	/** Val Quantity Multiples */
	val_quantity_multiples?: number;
	/** Um Purchase Order */
	um_purchase_order: string;
	/** Amt Quantity */
	amt_quantity?: number;
	/** Val Last Period Out Kg */
	val_last_period_out_kg?: number;
	/** Val Quantity Kg */
	val_quantity_kg?: number;
	/** Val Quantity Purchase Order */
	val_quantity_purchase_order?: number;
	/** Val Other Um */
	val_other_um: {
		[key: string]: number
	};
	/** An enumeration. */
	cod_order_status?: OrderStatus;
}

interface ModifyDimensionsSchema {
	/**
	 * Dat Order New
	 * @format date
	 */
	new_dat_order?: string;
	/**
	 * New Dat Delivery
	 * @format date
	 */
	new_dat_delivery?: string;
	/** New Val Quantity Multiples */
	new_val_quantity_multiples?: number;
	/** New Val Quantity Purchase Order */
	new_val_quantity_purchase_order?: number;
	/** New Val Quantity Kg */
	new_val_quantity_kg?: number;
	/** New Val Other Um */
	new_val_other_um?: object;
	/** Perc Gravity */
	perc_gravity?: number;
}

export interface InventoryReplenishmentExtendedSchema extends InventoryReplenishmentSchema, ModifyDimensionsSchema {
	/** Des Dc */
	des_dc: string;
	
}

interface InventoryAvailableItemsSchema {
	/**
	 * Cod Dc
	 * @default "000"
	 */
	cod_dc?: string;
	/** Cod Item */
	cod_item: string;
	/**
	 * Dat Order
	 * @format date
	 */
	dat_order: string;
	/**
	 * Dat Delivery
	 * @format date
	 */
	dat_delivery: string;
	/** Cod Division */
	cod_division: string;
	/** Des Division */
	des_division: string;
	/** Des Item */
	des_item?: string;
	/** Cod Product */
	cod_product: string;
	/** Des Product */
	des_product?: string;
	/** Cod Area */
	cod_area: string;
	/** Des Area */
	des_area: string;
	/** Cod Supplier */
	cod_supplier: string;
	/** Business Name */
	business_name?: string;
	/** Cod Purchase Address */
	cod_purchase_address?: string;
	/** Des Purchase Address */
	des_purchase_address?: string;
	/** Flg Order On Sale */
	flg_order_on_sale: boolean;
	/** Num Lead Time */
	num_lead_time: number;
	/** Num Multiples */
	num_multiples: number;
	/** Um Multiples */
	um_multiples: string;
	/** Um Purchase Order */
	um_purchase_order: string;
	/**
	 * Val Last Period Out Kg
	 * @default 0
	 */
	val_last_period_out_kg?: number;
	/**
	 * Des Dc
	 * @default "no warehouse"
	 */
	des_dc?: string;
}

export interface FilteringOrderingSchema {
	filters?: FilterGroupSchema;
	ordering?: Ordering;
}

export interface PaginationPayloadSchema {
	limit: number;
	offset: number;
}

export interface PaginationFilterPayloadSchema extends FilteringOrderingSchema, PaginationPayloadSchema {
}

export interface InventoryReplenishmentPaginationPayloadSchema extends PaginationFilterPayloadSchema, FiltersSchema {
	/** Urgent */
	urgent: boolean;
}

export interface RadioButtonType {
	value: string;
	name: string;
	notifications?: number;
}

export interface UnderstockCountType {
	count: number;
	loadingCount: boolean;
}

export interface ModalPropsType {
	open: boolean;
	toggleOpen: () => void;
	refresh: () => void;
}

export enum TypeEnum {
	ITEM = "item",
	DC = "dc",
}

export interface CustomOptionPropsType {
	data: OptionSchema;
	innerProps: ArticleProps<OptionSchema>;
}

export enum CustomSelection {
	missing_delivery_date = 1,
	including_delivery_date = 2,
	already_suggested = 3
}

export interface AvailableItemsInfo {
	/**
	 * Dat Delivery
	 * @format date
	 * @default null
	 */
	dat_delivery?: string;
	
	/** Um Multiples */
	um_multiples: string;
	
	/** Num Multiples */
	num_multiples: number;
	
	/** Num Lead Time */
	num_lead_time: number;
}

export interface AvailableItemsAdditionalInfo extends AvailableItemsInfo {
	new_dat_delivery?: string;
}


export interface CustomSelectionSchema {
	selection: CustomSelection;
	items_info: AvailableItemsInfo;
}

export interface InventoryAvailableItemsExtendedSchema extends InventoryAvailableItemsSchema {
	val_quantity_multiples: number;
	um_multiples: string;
	num_multiples: number;
}

export interface AvailableItemsModifySchema extends InventoryAvailableItemsExtendedSchema, ModifyDimensionsSchema {
}

export interface ModifyOrderPropsType {
	data: Partial<AvailableItemsModifySchema>;
	setData: Dispatch<SetStateAction<Partial<AvailableItemsModifySchema>>>
}

export interface CheckCustomOrderPropsType extends ModifyOrderPropsType {
	disabled: boolean;
}

export interface UmQuantityPropsType extends CheckCustomOrderPropsType {
	disabled: boolean;
	umDimension: "um_multiples";
	umValDimension: "num_multiples";
	dimensionName: "val_quantity_multiples";
}

export interface CustomOrderPropsType extends UmQuantityPropsType {
	leadtimeDimension: "num_lead_time";
}

export interface ModifyRowDialogPropsType extends ModalPropsType {
	refresh: () => void;
	data: Partial<InventoryReplenishmentExtendedSchema>;
	setData: Dispatch<SetStateAction<Partial<InventoryReplenishmentExtendedSchema>>>
}