import React, {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import Modal from "@ui-components/Modal";
import {TrashIcon} from "@heroicons/react/24/outline";
import Button from "@ui-components/Button";
import {api} from "@services/apiRequest";
import SearchSelect from "@ui-components/SearchSelect";
import Input from "@ui-components/Input";
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext";

export const ItemAssociationDialog = ({open, toggleOpen, rowData, refresh}) => {

  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  const intl = useIntl();

  const [data, setData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [productName, setProductName] = useState(null);

  const buildOption = ({cod_item, des_item, cod_dc}) => {
    return {value: cod_item, label: des_item, cod_dc: cod_dc}
  }
  const getProductsOption = (products) => {
    return products ? products.map((p) => buildOption(p)) : []
  }

  const fetchItemsFn = async (query) => {
    const params = {query};
    return await api.get("/entry/items_filtered", {params});
  }

  const text_semi_bold = "text-sm font-semibold leading-6 text-gray-900";


  const handleUpdate = () => {
    const _payload = {...data, des_product: productName}
    api
      .put(`entry/item_association/update_association`, _payload)
      .then(() => {
        push({type: "success", title: "Dati aggiornati con successo"});
        refresh();
        dataContext.triggerAssociateItemsRefresh();
        toggleOpen();
      })
      .catch((err) => {
        push({type: "error", title: "Errore imprevisto durante la modifica"});
        console.error(err)
      })
  }


  useEffect(() => {
      const _rowData = {
        cod_item: rowData.cod_item,
        des_item: rowData.des_item,
        cod_dc: rowData.cod_dc,
        replaces: rowData['replaces'].map(el => {
          return {
            cod_item: el.cod_item,
            des_item: el.des_item,
            cod_dc: el.cod_dc,
          }
        })
      }
      setProductName(rowData['replaces'].length > 0 ? rowData['replaces'][0]['des_product'] : null)
      setData(_rowData);
    }, [rowData]
  )


  return <>
    <Modal onExit={toggleOpen} opened={open}>
      <div className="flex flex-col gap-y-2 mx-5">
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            {intl.formatMessage({id: "what_article_substitute"})} {data && data.des_item}?
          </h3>
        </div>
      </div>
      <div className='mb-2'>
        <h2 className={text_semi_bold}>{intl.formatMessage({id: "product_name"})}</h2>
        <Input
          value={productName}
          onChange={(e) => {
            setProductName(e.target.value);
          }}
        />
      </div>
      <div>
        <h2 className={text_semi_bold}>{intl.formatMessage({id: "choose_substitute_article"})}</h2>
        <SearchSelect
          value={selectedItem}
          onChange={(value) => {
            setSelectedItem(value);
            const _appendedItem = {
              ...data, replaces: [...data['replaces'], {
                cod_item: value.value,
                des_item: value.label,
                cod_dc: value.cod_dc
              }]
            }
            setData(_appendedItem);
          }}
          openMenuOnClick={false}
          loadOptions={async (text, callback) => {
            try {
              const result = await fetchItemsFn(text);
              const options = getProductsOption(result.data);
              callback(options);
            } catch (error) {
              console.log(error);
            }
          }}
        />
      </div>
      <ul className="divide-y divide-gray-100">
        {data && data['replaces'].map((item) => (
          <li key={item.cod_item} className="relative flex items-center space-x-4 py-4">
            <div className="min-w-0 flex-auto">
              <p className={text_semi_bold}>{item.cod_item}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">{item.des_item}</p>
            </div>
            <Button
              styleType={"empty"}
              onClick={() => {
                const updateData = data['replaces'].filter(
                  (obj) => (obj.cod_item !== item.cod_item || obj.cod_dc !== item.cod_dc)
                );
                setData({...data, replaces: updateData});
              }}>
              <TrashIcon className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"/>
            </Button>
          </li>
        ))}
      </ul>
      <div className="mt-5 flex justify-between">
        <Button onClick={toggleOpen} styleType="empty">{intl.formatMessage({id: "exit"})}</Button>
        <Button onClick={handleUpdate}>{intl.formatMessage({id: "save"})}</Button>
      </div>
    </Modal>
  </>
}