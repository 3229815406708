import {api} from "@services/apiRequest";
import {InventoryReplenishmentExtendedSchema} from "../../types";

export const addOrder = (
  rowData: InventoryReplenishmentExtendedSchema,
  inventoryIdRun: number,
  refetch: Function
) => {

  const newOrder = {
    ...rowData,
    id_run: inventoryIdRun,
  }

  api.put('/orders/add_order', newOrder).then(() => refetch())
    .catch(e => console.error(e))
};