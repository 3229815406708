import React, {useState} from "react";
import {PalletizationHeader, PalletizationTable} from "@feature/entry/palletization";

export function Palletization() {
	
	const [showInvalidConstraints, setShowInvalidConstraints] = useState<boolean>(false);
	const [openCreateGroup, setOpenCreateGroup] = useState<boolean>(false);
	
	
	return (
		<>
			<PalletizationHeader
				setOpenCreateGroup={setOpenCreateGroup}
				showInvalidConstraints={showInvalidConstraints}
				setShowInvalidConstraints={setShowInvalidConstraints}/>
			<PalletizationTable
				showInvalidConstraints={showInvalidConstraints}
				setOpenCreateGroup={setOpenCreateGroup}
				openCreateGroup={openCreateGroup}/>
		</>
	)
}