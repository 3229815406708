import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import {GroupedOrderType, OrderStatus, OrderType} from "@feature/orders";
import {DataContext} from "@services/DataContext";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";

type T = GroupedOrderType;

const getDateTime = (date: string) => new Date(date).getTime();

export function useFetchOrders() {
	
	const intl = useIntl();
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	
	const [orders, setOrders] = useState<T[]>([]);
	const [historicOrders, setHistoricOrders] = useState<OrderType[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	
	
	const {refetchOrders} = useContext(DataContext) as { refetchOrders: boolean }
	
	useEffect(() => {
		setLoading(true);
		api.get<OrderType[]>('/orders')
			.then(({data}) => {
				const groupedBySupplier = data.filter(tR => tR.status === OrderStatus.CART)
					.reduce<T[]>((acc, {
						cod_supplier,
						business_name,
						cod_purchase_address,
						des_purchase_address,
						current_dat_order,
						current_dat_delivery,
						cod_dc,
						cod_item,
						des_item,
						current_val_quantity_multiples,
						um_multiples,
						current_val_quantity_purchase_order,
						um_purchase_order,
						dat_delivery,
					}) => {
						const i = acc.findIndex(
							ord =>
								ord.cod_supplier === cod_supplier
								&& getDateTime(ord.current_dat_delivery) === getDateTime(current_dat_delivery)
						)
						
						if (i === -1)
							acc.push({
								cod_supplier,
								business_name,
								cod_purchase_address,
								des_purchase_address,
								current_dat_order,
								current_dat_delivery,
								dat_delivery,
								items: [{
									cod_dc,
									cod_item,
									des_item,
									current_val_quantity_multiples,
									um_multiples,
									current_val_quantity_purchase_order,
									um_purchase_order,
									active: true,
								}]
							})
						else
							acc[i].items.push({
								cod_dc,
								cod_item,
								des_item,
								current_val_quantity_multiples,
								um_multiples,
								current_val_quantity_purchase_order,
								um_purchase_order,
								active: true,
							})
						return acc
					}, [])
				
				setOrders(groupedBySupplier);
				setHistoricOrders(
					data.filter(tR => tR.status !== OrderStatus.CART)
				);
			})
			.catch(err => {
				console.error(err);
				push({title: intl.formatMessage({id: "generic_error"}), type: "error"})
				throwError(err);
			})
			.finally(() => setLoading(false))
	}, [intl, push, refetchOrders, throwError])
	
	return {orders, historicOrders, loading}
}