import {useCallback, useEffect, useState} from "react";
import {components, GroupBase, MenuProps} from "react-select";
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import SearchSelect from "@ui-components/SearchSelect";
import Tooltip from "@ui-components/Tooltip";
import {ArticleProps, DimensionFilterProps, FiltersSchema, OptionSchema, OptionSchemaCallbackType} from "../types";
import {castFiltersToArrays} from "./lib/utils";
import {debounce} from "lodash";

export function DimensionFilter({
	                                dimension,
	                                label,
	                                filters,
	                                onFilterChange,
	                                endpointURL,
	                                classNames = "",
	                                showTooltip = false,
	                                tooltipText = "",
	                                maxOptions = 200,
	                                asyncProps = {}
                                }: DimensionFilterProps) {
	
	const [defaultOptions, setDefaultOptions] = useState<OptionSchema[]>([]);
	const [optionsLength, setOptionsLength] = useState<number>(0);
	const [loadingDefaultOptions, setLoadingDefaultOptions] = useState<boolean>(false);
	
	const intl = useIntl();
	
	const fetchItemsFn = useCallback(
		async (query: string) => {
			
			const requestBody = {
				field: dimension,
				field_value: query,
				filters: castFiltersToArrays(filters)
			};
			
			try {
				const {data} = await api.post<OptionSchema[]>(`${endpointURL}?result_limit=${maxOptions}`, requestBody)
				setOptionsLength(data.length);
				return data;
			} catch (e) {
				console.error(e);
				return [];
			}
			
		}, [dimension, filters, endpointURL, maxOptions]
	)
	
	useEffect(() => {
		setLoadingDefaultOptions(true);
		fetchItemsFn('')
			.then(value => setDefaultOptions(value))
			.finally(() => setLoadingDefaultOptions(false));
	}, [fetchItemsFn])
	
	const CustomMenu = (props: MenuProps<OptionSchema, true, GroupBase<OptionSchema>>) => {
		const msg = (msgId: string) => intl.formatMessage({id: msgId});
		return (
			<>
				{
					defaultOptions.length === maxOptions && optionsLength === maxOptions &&
          <div
            className="flex font-semibold text-gray-800 capitalize bg-white rounded-lg shadow-sm justify-center border border-gray-300">
            <span>
            {`${msg("firsts")} ${maxOptions} ${msg("results")}`}
          </span>
          </div>
				}
				<components.Menu<OptionSchema, true, GroupBase<OptionSchema>> {...props}>
					{props.children}
				</components.Menu>
			</>
		)
	}
	
	
	return (
		<div className={`flex items-center justify-evenly ${classNames}`}>
			{showTooltip && <Tooltip placement="right" text={tooltipText}/>}
			<SearchSelect
				label={intl.formatMessage({id: "filter"}) + " " + label.toLowerCase()}
				loadOptions={
					debounce(
						(inputValue: string, callback: OptionSchemaCallbackType) => fetchItemsFn(inputValue).then(r => callback(r)),
						1000
					)
				}
				isMulti={true}
				value={filters[dimension as keyof FiltersSchema]}
				onChange={(values: OptionSchema[]) => onFilterChange(values)}
				CustomOption={
					({data, innerProps, innerRef}: ArticleProps<OptionSchema>) => (
						<article ref={innerRef} {...innerProps}
						         className="px-4 py-2 text-sm cursor-pointer hover:bg-blue-100">
							<span><b>{data.value}</b> - {data.label}</span>
						</article>
					)
				}
				options={undefined}
				loadingMessage={() => intl.formatMessage({id: "loading"})}
				noOptionsMessage={() => intl.formatMessage({id: "no_options"})}
				defaultOptions={defaultOptions}
				components={{Menu: CustomMenu}}
				onClear={() => onFilterChange([])}
				isLoading={loadingDefaultOptions}
				{...asyncProps}
			/>
		</div>
	)
	
}