import React, {createContext, useState} from 'react';
import _ from "lodash";
import {useIntl} from "react-intl";
import {useDivisions} from "@feature/commons";
import {useDtnItemErrors, useEntryProductsAlertCounts} from "@feature/entry";
import {DEMAND_TARGETS, DETAIL_LEVELS, getDimensionRadios} from "@feature/demand"
import {HOME_TARGETS} from "@feature/home";
import {INVENTORY_TARGETS} from "@feature/inventory";
import {usePalletizationErrors} from "@feature/entry/palletization";

const DataContext = createContext({});
const {Provider} = DataContext;

const DataProvider = ({children}) => {

  const intl = useIntl();

  // hooks
  const {
    data: divisions,
    loading: loadingDivisions
  } = useDivisions();

  // Home
  const [homeFilters, setHomeFilters] = useState({
    dates: {
      date_start: undefined,
      date_end: undefined,
    },
    dimensionsFilters: HOME_TARGETS.reduce((acc, curr) => ({...acc, [curr]: []}), {}),
    selectedDivisions: undefined,
    ordering: "in",
  });

  // Demand
  const [demandRuns, setDemandRuns] = useState([]);
  const [demandCurrentRun, setDemandCurrentRun] = useState();

  const [demandFilters, setDemandFilters] = useState(
    DEMAND_TARGETS.reduce((obj, i) =>
      _.set(
        obj, i,
        (i === 'detail_level')
          ? DETAIL_LEVELS[0].value
          : (i === 'division')
            ? undefined
            : []
      ), {})
  );
  const [demandGroupKey, setDemandGroupKey] = useState(getDimensionRadios(intl)[0]);


  // Inventory
  const [inventoryRuns, setInventoryRuns] = useState([]);
  const [inventoryCurrentRun, setInventoryCurrentRun] = useState();
  const [inventoryFilters, setInventoryFilters] = useState(
    INVENTORY_TARGETS.reduce((obj, i) =>
      _.set(
        obj, i,
        i === 'division'
          ? undefined
          : []
      ), {})
  );

  // Data Entry

  // hooks
  const {setRefresh, errorCount: dtnItemsErrors, loading: dtnItemsLoading} = useDtnItemErrors();
  const {
    counts: itemsToAssociateCount,
    loading: associateErrorLoading,
    setRefreshTrigger: setAssociateItemsRefresh
  } = useEntryProductsAlertCounts();
  const {
    errorCount: palletizationErrors,
    loading: palletizationLoading,
    setRefresh: setPalletizationErrorRefresh
  } = usePalletizationErrors();

  //ORDERS
  const [refetchOrders, setRefetchOrders] = useState(false);

  return (
    <Provider
      value={{
        divisions,
        loadingDivisions,
        homeFilters,
        setHomeFilters: (v) => setHomeFilters(v),
        demandCurrentRun,
        setDemandCurrentRun: (v) => setDemandCurrentRun(v),
        demandRuns,
        setDemandRuns: (v) => setDemandRuns(v),
        demandFilters,
        setDemandFilters: (v) => setDemandFilters(v),
        demandGroupKey,
        setDemandGroupKey: (v) => setDemandGroupKey(v),
        inventoryRuns,
        setInventoryRuns: (v) => setInventoryRuns(v),
        inventoryCurrentRun,
        setInventoryCurrentRun: (v) => setInventoryCurrentRun(v),
        inventoryFilters,
        setInventoryFilters: (v) => setInventoryFilters(v),
        itemsToAssociateCount,
        associateErrorLoading,
        triggerAssociateItemsRefresh: () => setAssociateItemsRefresh(p => !p),
        dtnItemsErrors,
        dtnItemsLoading,
        triggerDtnItemRefresh: () => setRefresh(p => !p),
        palletizationErrors,
        palletizationLoading,
        triggerPalletizationRefresh: () => setPalletizationErrorRefresh(p => !p),
        refetchOrders,
        setRefetchOrders: (v) => setRefetchOrders(v),
      }}
    >
      {children}
    </Provider>
  );
};

export {DataContext, DataProvider}
