import {OrderStatus, OrderType} from "@feature/orders";
import {StatusBadge} from "@feature/orders/status-badge";
import {CustomEditDatePicker, CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {IntlShape} from "react-intl";
import {MTColumnsEditType} from "@feature/entry";
import {dateDiff} from "@utils/index";

const getStatusLocalization = (intl: IntlShape) =>
	Object.values(OrderStatus).reduce((acc, curr) => {
		if (curr === OrderStatus.CART)
			return acc
		acc[curr] = intl.formatMessage({id: curr})
		return acc
	}, {} as Record<OrderStatus, string>)

type T = OrderType;


export const getColumns = (intl: IntlShape): MTColumnsEditType<T> => [
	{
		title: intl.formatMessage({id: 'status'}),
		headerStyle: {textAlign: 'center'},
		lookup: getStatusLocalization(intl),
		field: 'status',
		render: rowData => <StatusBadge status={rowData.status}/>,
		cellStyle: {minWidth: 180}
	},
	{
		title: intl.formatMessage({id: 'cod_order'}),
		headerStyle: {textAlign: 'center'},
		field: 'cod_order',
		cellStyle: {textAlign: 'center'}
	},
	{
		title: intl.formatMessage({id: 'dat_order'}),
		field: 'dat_order',
		headerStyle: {textAlign: 'center'},
		type: 'date',
		editComponent: props => CustomEditDatePicker(props),
		filterComponent: props => CustomFilterDatePicker(props),
		render: rowData => new Intl.DateTimeFormat('it-IT').format(new Date(rowData.dat_order))
	},
	{
		title: intl.formatMessage({id: 'Fornitore'}),
		field: 'business_name',
		cellStyle: {textAlign: 'center'}
	},
	{
		title: intl.formatMessage({id: 'Materiale'}),
		field: 'des_item',
		cellStyle: {textAlign: 'center'}
	},
	{
		title: intl.formatMessage({id: 'quantity'}),
		field: 'current_val_quantity_multiples',
		type: 'numeric',
		cellStyle: {textAlign: 'center'},
		render: rowData => `${rowData.current_val_quantity_multiples} ${rowData.um_multiples}`
	},
	{
		title: intl.formatMessage({id: 'delivery_date'}),
		headerStyle: {textAlign: 'center'},
		field: 'dat_delivery',
		type: 'date',
		render: rowData => new Intl.DateTimeFormat('it-IT').format(new Date(rowData.current_dat_delivery)),
		editComponent: props => CustomEditDatePicker(props),
		filterComponent: props => CustomFilterDatePicker(props),
	},
	{
		title: intl.formatMessage({id: 'confirmation_time'}),
		headerStyle: {textAlign: 'center'},
		field: 'confirmation_time',
		type: 'numeric',
		cellStyle: {textAlign: 'center'},
		render: rowData => dateDiff(rowData.tms_confirmation, rowData.tms_sent) && (
			`${dateDiff(rowData.tms_confirmation, rowData.tms_sent)} ${intl.formatMessage({id: 'days'})}`
		)
	},
	{
		title: intl.formatMessage({id: 'delivery_time'}),
		headerStyle: {textAlign: 'center'},
		field: 'delivery_time',
		type: 'numeric',
		cellStyle: {textAlign: 'center'},
		render: rowData => dateDiff(rowData.tms_completion, rowData.tms_sent) && (
			`${dateDiff(rowData.tms_completion, rowData.tms_sent)} ${intl.formatMessage({id: 'days'})}`
		)
	}
]