import React from "react";
import {IntlShape} from "react-intl";
import {Options} from "@material-table/core";
import {MTColumnsType} from "@utils/pagination";
import MaterialTableCustomCell from "@ui-components/MaterialTableCustomCell";
import {CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {specialRound} from "@utils/functions";
import Tooltip from "@ui-components/Tooltip";
import {DivisionSchema, IS_DC_ENABLED} from "@feature/commons";
import {ADD_CART_COLOR, ORDER_CONFIRMED_COLOR} from "@theme/index";
import {InventoryReplenishmentExtendedSchema} from "../types";
import {OrderStatus} from "@feature/orders";

type T = InventoryReplenishmentExtendedSchema

export const getColumns = (divisions: DivisionSchema[], intl: IntlShape): MTColumnsType<T> => [
	{
		title: intl.formatMessage({id: "warehouse"}),
		field: "cod_dc",
		hidden: !IS_DC_ENABLED,
		render: rowData => <MaterialTableCustomCell vertical data1={rowData.cod_dc} data2={rowData.des_dc}/>,
		pagination: {
			spech: {
				column_name: ["cod_dc", "warehouse.des_dc"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "Divisione"}),
		field: "des_division",
		lookup: divisions.reduce((acc, {des_division}) => ({...acc, [des_division]: des_division}), {}),
		render: rowData => <small>{rowData.des_division}</small>,
		cellStyle: {maxWidth: 95},
		headerStyle: {maxWidth: 95},
		pagination: {
			spech: {
				column_name: ["cod_division"],
				filter_operator: "in",
				value_parser(value: string[]) {
					return divisions.filter(({des_division}) => value.includes(des_division)).map(({cod_division}) => cod_division)
				}
			}
		},
	},
	{
		title: intl.formatMessage({id: "Materiale"}),
		field: "des_item",
		render: rowData => <MaterialTableCustomCell vertical data1={rowData["cod_item"]} data2={rowData["des_item"]}/>,
		cellStyle: {minWidth: 180, paddingTop: 4, paddingBottom: 4, fontSize: 11},
		headerStyle: {minWidth: 180},
		pagination: {
			spech: {
				column_name: ["cod_item", "des_item"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "Gruppo_merce"}),
		field: "des_product",
		render: rowData => (
			<MaterialTableCustomCell
				vertical
				data1={rowData["cod_product"]}
				data2={rowData["des_product"]}/>
		),
		cellStyle: {minWidth: 150, paddingTop: 4, paddingBottom: 4, fontSize: 11},
		headerStyle: {minWidth: 150},
		pagination: {
			spech: {
				column_name: ["cod_product", "des_product"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "Fornitore"}),
		field: "business_name",
		render: rowData => (
			<MaterialTableCustomCell
				vertical
				data1={rowData["cod_supplier"]}
				data2={rowData["business_name"]}/>
		),
		cellStyle: {minWidth: 150, paddingTop: 4, paddingBottom: 4, fontSize: 11},
		headerStyle: {minWidth: 150},
		pagination: {
			spech: {
				column_name: ["cod_supplier", "business_name"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "address"}),
		field: "des_purchase_address",
		render: rowData => (
			<MaterialTableCustomCell
				data1={rowData["cod_purchase_address"]}
				vertical
				data2={rowData["des_purchase_address"]}/>
		),
		cellStyle: {minWidth: 150, paddingTop: 4, paddingBottom: 4, fontSize: 11},
		headerStyle: {minWidth: 150},
		pagination: {
			spech: {
				column_name: ["cod_purchase_address", "des_purchase_address"],
				filter_operator: "ilike any",
				value_parser(value) {
					return `%${value}%`
				},
			}
		},
	},
	{
		title: intl.formatMessage({id: "on_sale"}),
		field: "flg_order_on_sale",
		type: "boolean",
	},
	{
		title: intl.formatMessage({id: "order_date"}),
		field: "dat_order",
		type: "date",
		pagination: {
			spech: {
				column_name: ["dat_order", "new_dat_order"],
			}
		},
		filterComponent: (props) => <CustomFilterDatePicker {...props} />,
		render: rowData => (
			<MaterialTableCustomCell
				data1={new Intl.DateTimeFormat('it-IT').format(new Date(rowData["dat_order"]))}
				substitution_value={
					rowData?.new_dat_order && new Intl.DateTimeFormat('it-IT').format(new Date(rowData["new_dat_order"]))
				}/>
		)
	},
	{
		title: intl.formatMessage({id: "expected_delivery"}),
		field: "dat_delivery",
		type: "date",
		pagination: {
			spech: {
				column_name: ["dat_delivery", "new_dat_delivery"],
			}
		},
		filterComponent: (props) => <CustomFilterDatePicker {...props} />,
		render: rowData => (
			<MaterialTableCustomCell
				data1={new Intl.DateTimeFormat('it-IT').format(new Date(rowData["dat_delivery"]))}
				substitution_value={
					rowData?.new_dat_delivery && new Intl.DateTimeFormat('it-IT').format(new Date(rowData["new_dat_delivery"]))
				}/>
		)
	},
	{
		title: intl.formatMessage({id: "quantity"}),
		field: "val_quantity_multiples",
		type: "numeric",
		pagination: {
			spech: {
				column_name: ["val_quantity_multiples", "new_val_quantity_multiples"],
			}
		},
		render: rowData => (
			<MaterialTableCustomCell
				data1={specialRound(rowData["val_quantity_multiples"]).toLocaleString("it")}
				data2={rowData["um_multiples"]}
				substitution_value={rowData?.new_val_quantity_multiples}/>
		),
	},
	{
		title: `${intl.formatMessage({id: "quantity"})} ${intl.formatMessage({id: "Fornitore"})}`,
		field: "val_quantity_purchase_order",
		type: "numeric",
		pagination: {
			spech: {
				column_name: ["val_quantity_purchase_order", "new_val_quantity_purchase_order"],
			}
		},
		render: rowData => (
			<MaterialTableCustomCell
				data1={specialRound(rowData["val_quantity_purchase_order"], 1000).toLocaleString("it")}
				data2={rowData["um_purchase_order"]}
				substitution_value={rowData?.new_val_quantity_purchase_order}/>
		),
	},
	{
		title: intl.formatMessage({id: "weight"}),
		field: "val_quantity_kg",
		type: "numeric",
		pagination: {
			spech: {
				column_name: ["val_quantity_kg", "new_val_quantity_kg"],
			}
		},
		render: rowData => (
			<MaterialTableCustomCell
				data1={specialRound(rowData["val_quantity_kg"], 1000).toLocaleString("it")}
				data2='KG'
				substitution_value={rowData?.new_val_quantity_kg}/>
		),
	},
	{
		title: intl.formatMessage({id: "other_measures"}),
		field: "val_other_um",
		filtering: false,
		sorting: false,
		render: rowData => (
			<Tooltip
				text={
					Object.keys(rowData?.new_val_other_um ?? rowData["val_other_um"]).sort().filter(
						k => rowData.val_other_um[k]
					).map(
						k => `${rowData.val_other_um[k].toFixed(2)} ${k}`
					)
				}
				iconName="InboxStackIcon"/>
		)
	},
	{
		title: intl.formatMessage({id: "last_weeks"}, {weeknum: 5}),
		field: "val_last_period_out_kg",
		type: "numeric",
		render: rowData => rowData.val_last_period_out_kg
			? <MaterialTableCustomCell
				data1={specialRound(rowData["val_last_period_out_kg"], 1000)?.toLocaleString("it")}
				data2='KG'/>
			: <span className="flex justify-center items-center">-</span>,
	},
	{
		title: "Status",
		field: "cod_order_status",
		hidden: true,
	},
];

export const OPTIONS: Options<T> = {
	headerStyle: {paddingRight: 2, fontSize: 12},
	rowStyle: (rowData: T) => {
		if (!rowData.cod_order_status)
			return {};
		
		if (rowData.cod_order_status === OrderStatus.CART)
			return {backgroundColor: ADD_CART_COLOR}
		else
			return {backgroundColor: ORDER_CONFIRMED_COLOR}
	}
};

export const getInventoryExportColumnMappings = (intl: IntlShape) => {
	const msg = (msgId: string, translation_value?: Record<string, any>) => intl.formatMessage({id: msgId}, translation_value);
	
	const unpackColAttr = (field: string, translationKey: string) => {
		
		const translation = msg(translationKey);
		
		const cod_field = 'cod_' + field;
		const des_field = 'des_' + field;
		
		return {[`${msg("code")} ${translation}`]: cod_field, [msg(translationKey)]: des_field}
	}
	
	return {
		...(IS_DC_ENABLED ? unpackColAttr('dc', 'warehouse') : {}),
		[msg("Divisione")]: "des_division",
		...unpackColAttr('item', 'Materiale'),
		...unpackColAttr('product', 'Gruppo_merce'),
		[`${msg("code")} ${msg("Fornitore")}`]: "cod_supplier",
		[msg("Fornitore")]: "business_name",
		...unpackColAttr('purchase_address', 'address'),
		[msg("on_sale")]: "flg_order_on_sale",
		[msg("order_date")]: "dat_order",
		[msg("expected_delivery")]: "dat_delivery",
		[msg("quantity")]: "val_quantity_multiples",
		[msg("quantity") + " " + msg("Fornitore")]: "val_quantity_purchase_order",
		[msg("weight")]: "val_quantity_kg",
		[msg("other_measures")]: "val_other_um",
		[msg("last_weeks", {weeknum: 5})]: "val_last_period_out_kg",
	}
}