import React, {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {CheckCircleIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {NotificationsContext} from "@ui-components/Notifications";
import Button from "@ui-components/Button";
import {ApplyAllSchema, PalletizationSchema} from "@feature/entry";
import {BasicInput, Box, UmChoice} from "@feature/entry/palletization/generic";
import {saveNewConstraints} from "@feature/entry/palletization/lib/utils";
import {ENTRY_UM} from "@feature/entry/lib/constants";

type UpdatableFieldsType = Pick<ApplyAllSchema, 'num_multiples' | 'um_multiples' | 'num_lead_time_custom'>

export function ApplyAllAccordion({refetch, itemConstraints}: {
	refetch: () => void,
	itemConstraints: PalletizationSchema
}) {
	
	const intl = useIntl();
	
	const {push} = useContext(NotificationsContext);
	
	const [open, setOpen] = useState(false);
	const [applyAllData, setApplyAllData] = useState<ApplyAllSchema>();
	
	useEffect(
		() => {
			setApplyAllData(itemConstraints)
		}, [itemConstraints]);
	
	const msg = (id: string) => intl.formatMessage({id});
	
	const updateConstraints = (key: keyof UpdatableFieldsType, value: string | number | undefined) => {
		setApplyAllData(prevState => {
				if (prevState)
					return {...prevState, [key]: value}
			}
		);
	}
	
	return (
		<div className="flex flex-col items-center justify-center w-full border border-gray-800 rounded-md p-2">
			<div className="flex w-full items-center justify-evenly">
				<ChevronRightIcon
					onClick={() => setOpen(prevState => !prevState)}
					className={`w-7 h-7 text-gray-500 ${open ? 'rotate-90' : ''} transition-all duration-500 ease-out rounded-full p-1 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-700`}/>
				<span className="text-gray-700 font-bold capitalize">{msg('apply_all')}</span>
				<Button
					onClick={() => saveNewConstraints(applyAllData!, refetch, 'item/all', push, intl)}
					disabled={
						(!applyAllData?.num_lead_time_custom && !applyAllData?.num_multiples && !applyAllData?.um_multiples) || !open
					}>
					<CheckCircleIcon className="h-5 w-5 mr-2"/>
					{msg('save')}
				</Button>
			</div>
			<div
				className={`${open ? 'h-40' : 'h-0'} transition-all duration-200 overflow-hidden ease-in-out flex items-center justify-around`}>
				<Box title={msg('order_multiple')} disabled={!open}>
					<div className="flex justify-around items-center gap-x-3 my-auto">
						<BasicInput
							value={applyAllData?.num_multiples}
							onChange={newValue => updateConstraints('num_multiples', newValue)}
							label={msg('multiple')}/>
						<UmChoice
							value={applyAllData?.um_multiples ?? '-'}
							onChange={newValue => updateConstraints('um_multiples', newValue)}
							options={ENTRY_UM}/>
					</div>
				</Box>
				<Box
					title={msg("custom_lead_time")}
					disabled={!open}
					enableTooltip
					tooltipPosition="left"
					tooltipMessage={msg('custom_lead_time_tooltip')}>
					<BasicInput
						value={applyAllData?.num_lead_time_custom}
						onChange={newValue => updateConstraints('num_lead_time_custom', newValue)}
						label="Lead Time Custom"/>
				</Box>
			</div>
		</div>
	)
}