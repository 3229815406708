import {ChangeEvent, Dispatch, ReactElement, SetStateAction} from "react";
import {BulkEditType, EditFieldsType, MTColumnsEditType} from "@feature/entry";
import {IntlShape} from "react-intl";
import {Filter, Query} from "@material-table/core";
import {api} from "@services/apiRequest";
import Input from "@ui-components/Input";
import DatePicker from "@ui-components/DatePicker";
import {dateToString} from "@utils/index";
import Select from "@ui-components/Select";
import {buildFilterFromQuery} from "@utils/pagination";

export const fieldAlreadyExists = <T extends object>(field: string, editFields: EditFieldsType<T>[]) => editFields.some(({field: f}) => f === field)
export const getChangeList = <T extends object>(
  bulkEditValues: BulkEditType[] | Filter<T>[],
  isAllBulkEdit: boolean,
  intl: IntlShape
) => {
  const msg = (id: string) => intl.formatMessage({id})

  const checkTitleType = (bulkValue: Filter<T>) => {
    if (typeof bulkValue.column.title === 'string')
      return <li key={bulkValue.column.title}>{bulkValue.column.title}: {bulkValue.value}</li>
    else
      throw new Error('React Components as title are not supported')
  }
  return isAllBulkEdit
    ? (
      <ul>
        {(bulkEditValues as Filter<T>[]).map(
          (v) => checkTitleType(v)
        )}
      </ul>
    )
    : (
      <ul>
        {(bulkEditValues as BulkEditType[]).map(
          ({cod_dc, cod_item}) => (
            <li key={cod_item}>{msg('warehouse')} {cod_dc} - {msg("Materiale")} {cod_item}</li>
          )
        )}
      </ul>
    )
}

export const handleFieldChange = <T extends object>(
  newField: EditFieldsType<T>,
  setEditFields: Dispatch<SetStateAction<EditFieldsType<T>[]>>,
  editFields: EditFieldsType<T>[],
) => {
  if (fieldAlreadyExists(newField.field as string, editFields)) {
    setEditFields(
      prevState => prevState.filter(({field}) => field !== newField.field)
    )
  } else
    setEditFields(
      prevState => [...prevState, newField]
    )
}


export const handleSave = <T extends object>(
  editFields: EditFieldsType<T>[],
  bulkEditValues: BulkEditType[] | Filter<T>[],
  query: Query<T>,
  columns: MTColumnsEditType<T>,
  isAllBulkEdit: boolean,
  onExit: () => void,
  refresh: () => void,
  push: (notification: { type: "success" | "error", title: string }) => void,
  intl: IntlShape,
) => {
  const msg = (id: string) => intl.formatMessage({id})

  const editFieldsObject = editFields.reduce(
    (acc, {field, value}) => ({...acc, [field as string]: value}),
    {}
  )

  let endpoint = 'entry/item/update'

  let body;

  if (isAllBulkEdit) {
    endpoint += '/all'
    body = {
      filters: buildFilterFromQuery(query, columns),
      modified_values: editFieldsObject
    }
  } else
    body = (bulkEditValues as BulkEditType[]).map(v => ({...v, ...editFieldsObject}))

  api.post(endpoint, body).then(() => {
    push({
      type: "success",
      title: msg("edit_success")
    })
    onExit();
    refresh();
  }).catch((e) => {
    console.error(e)
    push({
      type: "error",
      title: msg("generic_error")
    })
    onExit();
  })
}

export const getEditComponent = <T extends object>(
  editType: EditFieldsType<T>['type'],
  lookup: EditFieldsType<T>['lookup'],
  value: EditFieldsType<T>['value'],
  field: EditFieldsType<T>['field'],
  setEditFields: Dispatch<SetStateAction<EditFieldsType<T>[]>>,
) => {
  let editComponent: ReactElement;

  const handleChanges = (newValue: string | number | undefined) =>
    setEditFields(
      prevState => {
        const newEditFields = [...prevState];
        const currentField = newEditFields.find(({field: thisField}) => thisField === field);
        currentField!['value'] = newValue;
        return newEditFields;
      }
    )

  switch (editType) {
    case 'numeric':
      editComponent = (
        <Input
          type="number"
          value={value}
          label={undefined}
          name={undefined}
          validation={false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChanges(e.target.value)}
          required
          errorMessage={undefined}
          showCalendar={undefined}
          onClear={() => handleChanges(undefined)}/>
      )
      break;
    case 'date':
      editComponent = (
        <DatePicker value={value} onChange={(d: Date) => handleChanges(dateToString(d))} minDate={undefined}/>
      )
      break;
    case 'string' && lookup: {
      const options = Object.entries(lookup!).map(([value, label]) => ({value, label}));
      editComponent = (
        <Select
          options={options}
          value={
            options.find(({value: v}) => v === value)
          }
          label={undefined}
          onChange={undefined}
          customStyles={undefined}/>
      )
    }
      break;
    default:
      editComponent = (
        <Input
          type="text"
          value={value}
          label={undefined}
          name={undefined}
          validation={false}
          required
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChanges(e.target.value)}
          errorMessage={undefined}
          showCalendar={undefined}
          onClear={() => handleChanges(undefined)}/>
      )
  }
  return editComponent;
}